import React from 'react'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'

interface AIStepProps {
    illustration: string
    content: string
}

export const AIStep = ({ illustration, content }: AIStepProps) => (
    <Container>
        <Header>
            <Illustration image={illustration} />
        </Header>
        {content}
    </Container>
)

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 23%;
    white-space: pre-wrap;
    text-align: center;
    ${({ theme }) => theme.typography_presets.bold}

    @media (max-width: 500px) {
        width: 48%;

        &:nth-child(1),
        &:nth-child(2) {
            margin-bottom: 24px;
        }
    }
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
`

const Illustration = styled.div<{ image: string }>`
    position: relative;
    width: 120px;
    height: 120px;
    background-color: #f4f4f4;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.accent};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 80px;
        height: 80px;
    }

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 60px;
        height: 50px;
        background-image: url('${({ image }) =>
            withPrefix(`/images/ai/${image}`)}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        z-index: 1;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            width: 36px;
            height: 33px;
        }
    }
`
