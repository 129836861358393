import React, { Fragment } from 'react'
import styled from 'styled-components'
import { LocaleConfig, useTranslations } from '../core'
import { graphql, useStaticQuery } from 'gatsby'

type BrowserId = 'Chrome' | 'Android_Chrome' | 'Edge' | 'Safari' | 'iOS_Safari'

interface BrowserConfig {
    id: BrowserId
    name: string
}

export interface BrowserSupportTableData {
    allBrowserSupportYaml: {
        edges: Array<{
            node: Record<BrowserId, string>
        }>
    }
    allOperatingEnvironmentsYaml: {
        edges: Array<{
            node: {
                operating_environments: {
                    browsers: Array<BrowserConfig>
                    last_update: string
                }
            }
        }>
    }
}

interface BrowserSupportTableProps {
    locale: LocaleConfig
}

export const BrowserSupportTable = ({ locale }: BrowserSupportTableProps) => {
    const { translate } = useTranslations(locale.locale)

    const data: BrowserSupportTableData = useStaticQuery(graphql`
        query BrowserSupportQuery {
            allBrowserSupportYaml {
                edges {
                    node {
                        Android_Chrome
                        Chrome
                        Edge
                        Safari
                        iOS_Safari
                    }
                }
            }
            allOperatingEnvironmentsYaml {
                edges {
                    node {
                        operating_environments {
                            browsers {
                                id
                                name
                            }
                            last_update(formatString: "LL", locale: "ja")
                        }
                    }
                }
            }
        }
    `)

    const supportedBrowsersVersion = data.allBrowserSupportYaml.edges[0].node
    const { last_update, browsers } =
        data.allOperatingEnvironmentsYaml.edges[0].node.operating_environments
    const operatingEnvironments = browsers.map(({ id, name }) => {
        const version = supportedBrowsersVersion[id]
        return {
            id,
            name,
            version,
        }
    })

    return (
        <BrowserSupportTableContent>
            <Table>
                <Header>{translate('operating_environment')}</Header>
                <Header>{translate('operating_environment_version')}</Header>
                {operatingEnvironments.map(({ id, name, version }) => (
                    <Fragment key={id}>
                        <EnvironmentName>{name}</EnvironmentName>
                        <EnvironmentVersion data-testid={`${id}-version`}>
                            {version} {translate('or_higher')}
                        </EnvironmentVersion>
                    </Fragment>
                ))}
            </Table>
            <LastUpdateLabel>{`${translate(
                'operation_environment_update_label'
            )} ${last_update}`}</LastUpdateLabel>
        </BrowserSupportTableContent>
    )
}

const BrowserSupportTableContent = styled.div``

const Table = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Cell = styled.div`
    width: 50%;
    text-align: center;
    background-color: #f4f4f4;
    font-size: 14px;
    padding: 12px 0;
    border-bottom: 2px solid white;

    @media (max-width: 500px) {
        &:nth-child(2n - 1) {
            width: 70%;
        }
        &:nth-child(2n) {
            width: 30%;
        }
    }
`

const Header = styled(Cell)`
    background-color: black;
    color: white;
    ${({ theme }) => theme.typography_presets.bold}

    &:first-child {
        border-right: 2px solid white;
    }
`

const EnvironmentName = styled(Cell)`
    border-right: 2px solid white;
`

const EnvironmentVersion = styled(Cell)``

const LastUpdateLabel = styled.div`
    width: 100%;
    text-align: right;
    font-size: 13px;
    ${({ theme }) => theme.typography_presets.bold};
`
