import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {
    PageSubTitle,
    ContentSpacer,
    Container,
    LocaleConfig,
} from 'modules/core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from 'modules/core/graphql'

interface MotivationProps {
    locale: LocaleConfig
    title: string
    content: string
}

export interface MotivationData {
    allMotivationYaml: {
        edges: Array<{
            node: {
                id: string
                title: {
                    ja: string
                    en: string
                }
                content: {
                    ja: string
                    en: string
                }
                illustration: string
            }
        }>
    }
    allFile: ImageFilesData
}

export const Motivation = ({ locale, title, content }: MotivationProps) => {
    const data: MotivationData = useStaticQuery(graphql`
        query MotivationQuery {
            allMotivationYaml {
                edges {
                    node {
                        id: yamlId
                        title {
                            ja
                            en
                        }
                        content {
                            ja
                            en
                        }
                        illustration
                    }
                }
            }
            allFile(
                filter: { relativePath: { glob: "features/motivation/*.png" } }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                height: 192
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const illustrations = flattenEdgesNodes(data.allFile)
    const items = flattenEdgesNodes(data.allMotivationYaml).map((node) => ({
        ...node,
        illustration: getMatchingImageSrc(illustrations, node.illustration)!,
    }))

    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer />
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <ContentSpacer />
            <Cards>
                {items.map((item) => (
                    <Card key={item.id}>
                        <CardIllustration
                            image={item.illustration}
                            data-testid="features-motivation-illustration"
                        />
                        <CardTitle>{item.title[locale.locale]}</CardTitle>
                        <CardContent>{item.content[locale.locale]}</CardContent>
                    </Card>
                ))}
            </Cards>
        </Container>
    )
}

const Content = styled.div``

const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const Card = styled.div`
    width: 31%;

    @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 48px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

const CardIllustration = styled.div<{ image: string }>`
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 192px;
`

const CardTitle = styled.h3`
    margin: 10px 0 2px 0;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-weight: 400;
`

const CardContent = styled.div`
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
`
