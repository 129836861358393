import React from 'react'
import { withPrefix, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Container, PageSubTitle, ContentSpacer, LocaleConfig } from '../core'
import { AIStep } from './AIStep'

interface AIProps {
    locale: LocaleConfig
    title: string
    content: string
}

export interface AIData {
    allAiStepsYaml: {
        edges: Array<{
            node: {
                id: string
                content: {
                    ja: string
                    en: string
                }
            }
        }>
    }
}

export const AI = ({ locale, title, content }: AIProps) => {
    const data: AIData = useStaticQuery(graphql`
        query AIStepsQuery {
            allAiStepsYaml {
                edges {
                    node {
                        id: yamlId
                        content {
                            ja
                            en
                        }
                    }
                }
            }
        }
    `)

    const steps = data.allAiStepsYaml.edges.map((edge) => ({
        ...edge.node,
        illustration: `${edge.node.id}.svg`,
    }))

    return (
        <Container>
            <InnerContainer>
                <Info>
                    <AITitle dangerouslySetInnerHTML={{ __html: title }} />
                    <ContentSpacer />
                    <Content dangerouslySetInnerHTML={{ __html: content }} />
                </Info>
                <AIStrength />
            </InnerContainer>
            <ContentSpacer size="large" />
            <Steps>
                {steps.map((step) => (
                    <AIStep
                        key={step.id}
                        illustration={step.illustration}
                        content={step.content[locale.locale]}
                    />
                ))}
            </Steps>
        </Container>
    )
}

const AITitle = styled(PageSubTitle)`
    line-height: 40px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        line-height: 30px;
    }
`

const Content = styled.div``

const Steps = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const InnerContainer = styled.div`
    display: flex;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column;
    }
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin-bottom: 8px;
    }
`

const AIStrength = styled.div`
    width: 50%;
    background-image: url('${withPrefix('/images/30_strength.png')}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        height: 242px;
        margin-bottom: 16px;
    }
`
