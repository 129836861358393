import React from 'react'
import styled from 'styled-components'
import { Container, PageSubTitle, ContentSpacer, LocaleConfig } from '../core'
import { ContentPeople } from './ContentPeople'
import { ContentFeatures } from './ContentFeatures'

interface RichOriginalContentProps {
    locale: LocaleConfig
    title: string
    content: string
}

export const RichOriginalContent = ({
    locale,
    title,
    content,
}: RichOriginalContentProps) => {
    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer />
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <ContentSpacer />
            <ContentPeople />
            <ContentSpacer size="large" />
            <ContentFeatures locale={locale} />
        </Container>
    )
}

const Content = styled.div``
