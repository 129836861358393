import React from 'react'
import styled from 'styled-components'
import { PageSubTitle, ContentSpacer, Container, LocaleConfig } from '../core'
import { BrowserSupportTable } from '../browser-support/BrowserSupportTable'

interface OperatingEnvironmentProps {
    locale: LocaleConfig
    title: string
    content: string
}

export const OperatingEnvironment = ({
    locale,
    title,
    content,
}: OperatingEnvironmentProps) => {
    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer />
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <BrowserSupportTable locale={locale} />
        </Container>
    )
}

const Content = styled.div``
