import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {
    Container,
    PageSubTitle,
    ContentSpacer,
    LocaleConfig,
    useTranslations,
} from '../core'
import { CourseOutlineTable } from './CourseOutlineTable'

interface CoursesOutlineProps {
    locale: LocaleConfig
    title: string
}

export interface CoursesOutlineData {
    allToeicCoursesOutlineYaml: {
        edges: Array<{
            node: {
                toeicCourseOutline: Array<{
                    id: string
                    heading: {
                        ja: string
                        en: string
                    }
                    content: {
                        ja: string
                        en: string
                    }
                }>
            }
        }>
    }
    allGeneralenglishCoursesOutlineYaml: {
        edges: Array<{
            node: {
                genericEnglishCourseOutline: Array<{
                    id: string
                    heading: {
                        ja: string
                        en: string
                    }
                    content: {
                        ja: string
                        en: string
                    }
                }>
            }
        }>
    }
}

export const CoursesOutline = ({ locale, title }: CoursesOutlineProps) => {
    const { translate } = useTranslations(locale.locale)
    const data: CoursesOutlineData = useStaticQuery(graphql`
        query CoursesOutlineQuery {
            allToeicCoursesOutlineYaml {
                edges {
                    node {
                        toeicCourseOutline {
                            id
                            heading {
                                ja
                                en
                            }
                            content {
                                ja
                                en
                            }
                        }
                    }
                }
            }
            allGeneralenglishCoursesOutlineYaml {
                edges {
                    node {
                        genericEnglishCourseOutline {
                            id
                            heading {
                                ja
                                en
                            }
                            content {
                                ja
                                en
                            }
                        }
                    }
                }
            }
        }
    `)

    const toeicCourseOutlineData =
        data.allToeicCoursesOutlineYaml.edges[0].node.toeicCourseOutline.map(
            (entry) => ({
                id: entry.id,
                heading: entry.heading[locale.locale],
                content: entry.content[locale.locale],
            })
        )

    const generalEnglishCourseOutlineData =
        data.allGeneralenglishCoursesOutlineYaml.edges[0].node.genericEnglishCourseOutline.map(
            (entry) => ({
                id: entry.id,
                heading: entry.heading[locale.locale],
                content: entry.content[locale.locale],
            })
        )

    return (
        <Container>
            <PageSubTitle>{title}</PageSubTitle>
            <ContentSpacer size="large" />
            <Courses>
                <CourseWrapper>
                    <CourseHeader>
                        {translate('toeic_course_outline')}
                    </CourseHeader>
                    <ContentSpacer />
                    <CourseOutlineTable
                        data={toeicCourseOutlineData}
                        data-testid="features-coursesoutlinetable-toeic"
                    />
                </CourseWrapper>
                <CourseWrapper>
                    <CourseHeader>
                        {translate('general_english_course_outline')}
                    </CourseHeader>
                    <ContentSpacer />
                    <CourseOutlineTable
                        data={generalEnglishCourseOutlineData}
                        data-testid="features-coursesoutlinetable-generalenglish"
                    />
                </CourseWrapper>
            </Courses>
        </Container>
    )
}

const Courses = styled.div`
    width: 100%;
    position: relative;
`

const CourseWrapper = styled.div`
    &:first-child {
        margin-bottom: 64px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            margin-bottom: 32px;
        }
    }
`

const CourseHeader = styled.div`
    display: inline-block;
    color: white;
    background: black;
    font-size: 20px;
    line-height: 30px;
    padding: 9px 24px;
    ${({ theme }) => theme.typography_presets.bold}

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 14px;
        line-height: 21px;
    }
`
