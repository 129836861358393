import React from 'react'
import { graphql } from 'gatsby'
import {
    Layout,
    SEO,
    PageTitle,
    SectionSpacer,
    usePageSections,
    useTranslations,
    LocaleConfig,
} from '../modules/core'
import {
    AI,
    RichOriginalContent,
    Motivation,
    OperatingEnvironment,
} from '../modules/features'
import { CoursesOutline } from '../modules/features/CoursesOutline'

type FeaturesSection =
    | 'ai'
    | 'richOriginalContent'
    | 'motivation'
    | 'coursesOutline'
    | 'operatingEnvironments'

interface FeaturesData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: FeaturesSection
                    title: string
                }
                html: string
            }
        }>
    }
}

export const query = graphql`
    query FeaturesQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    page: { eq: "features" }
                    locale: { eq: $locale }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface FeaturesPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: FeaturesData
}

const FeaturesPage = ({ path, pageContext, data }: FeaturesPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    const sections = usePageSections<FeaturesSection>(data)

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={translate('page_features')} />
            <PageTitle>{translate('page_features')}</PageTitle>
            <AI
                locale={pageContext.localeConfig}
                title={sections.ai.title}
                content={sections.ai.content}
            />
            <SectionSpacer />
            <CoursesOutline
                locale={pageContext.localeConfig}
                title={sections.coursesOutline.title}
            />
            <SectionSpacer />
            <RichOriginalContent
                locale={pageContext.localeConfig}
                title={sections.richOriginalContent.title}
                content={sections.richOriginalContent.content}
            />
            <SectionSpacer />
            <Motivation
                locale={pageContext.localeConfig}
                title={sections.motivation.title}
                content={sections.motivation.content}
            />
            <SectionSpacer />
            <OperatingEnvironment
                locale={pageContext.localeConfig}
                title={sections.operatingEnvironments.title}
                content={sections.operatingEnvironments.content}
            />
        </Layout>
    )
}

export default FeaturesPage
