import React from 'react'
import styled from 'styled-components'

interface CourseOutlineTableProps {
    data: Array<{
        id: string
        heading: string
        content: string
    }>
    'data-testid': string
}

export const CourseOutlineTable = ({
    data,
    'data-testid': dataTestId,
}: CourseOutlineTableProps) => {
    return (
        <Table data-testid={dataTestId}>
            <TableBody>
                {data.map((row) => (
                    <TableRow key={row.id}>
                        <TableHeading>{row.heading}</TableHeading>
                        <TableColumn>{row.content}</TableColumn>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    line-height: 24px;
`
const TableBody = styled.tbody``
const TableHeading = styled.th`
    width: 160px;
    vertical-align: top;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.accent};
    border-right: 1px solid white;
    ${({ theme }) => theme.typography_presets.bold}

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        padding: 8px;
        text-align: left;
        border-right: 0;
        border-bottom: 1px solid white;
    }
`
const TableRow = styled.tr`
    border-bottom: 1px solid white;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        display: flex;
        flex-direction: column;
    }
`
const TableColumn = styled.td`
    background-color: #f4f4f4;
    padding: 16px 24px;
    white-space: pre-wrap;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 8px;
    }
`
