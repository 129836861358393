import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from '../core/graphql'

export interface ContentPeopleData {
    allContentPeopleYaml: {
        edges: {
            node: {
                name: string
                role: string
                avatar: string
            }
        }[]
    }
    allFile: ImageFilesData
}

export const ContentPeople = () => {
    const data: ContentPeopleData = useStaticQuery(graphql`
        query ContentPeopleQuery {
            allContentPeopleYaml {
                edges {
                    node {
                        name
                        role
                        avatar
                    }
                }
            }
            allFile(
                filter: { relativePath: { glob: "features/advisors/*.png" } }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 280
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const avatars = flattenEdgesNodes(data.allFile)
    const people = flattenEdgesNodes(data.allContentPeopleYaml).map((node) => ({
        ...node,
        avatar: getMatchingImageSrc(avatars, node.avatar)!,
    }))

    return (
        <Container>
            {people.map((person) => (
                <Person
                    key={person.name}
                    name={person.name}
                    role={person.role}
                    avatar={person.avatar}
                />
            ))}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Person = ({
    name,
    role,
    avatar,
}: {
    name: string
    role: string
    avatar: string
}) => {
    return (
        <PersonContainer>
            <PersonAvatar
                image={avatar}
                data-testid="features-contentpeople-avatar"
            />
            <PersonInfo>
                <PersonName>{name}</PersonName>
                {role}
            </PersonInfo>
        </PersonContainer>
    )
}

const PersonContainer = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 500px) {
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
            margin-bottom: 16px;
        }
    }
`

const PersonName = styled.div`
    ${({ theme }) => theme.typography_presets.bold};
`

const PersonAvatar = styled.div<{ image: string }>`
    width: 140px;
    height: 140px;
    margin-bottom: 18px;
    background-image: url('${({ image }) => image}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 130px;
        height: 130px;
        margin-bottom: 9px;
    }
`

const PersonInfo = styled.div`
    text-align: center;
    white-space: pre;
    font-size: 14px;
`
