import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, withPrefix } from 'gatsby'
import { LocaleConfig, Tabs, Tab as TabType } from 'modules/core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from 'modules/core/graphql'

export interface ContentFeaturesData {
    allContentFeaturesYaml: {
        edges: {
            node: {
                id: string
                icon: string
                title: { ja: string; en: string }
                content: { ja: string; en: string }
                illustration: string
            }
        }[]
    }
    allFile: ImageFilesData
}

interface ContentFeaturesProps {
    locale: LocaleConfig
}

export const ContentFeatures = ({ locale }: ContentFeaturesProps) => {
    const data = useStaticQuery<ContentFeaturesData>(graphql`
        query ContentFeaturesQuery {
            allContentFeaturesYaml {
                edges {
                    node {
                        id: yamlId
                        icon
                        title {
                            ja
                            en
                        }
                        content {
                            ja
                            en
                        }
                        illustration
                    }
                }
            }
            allFile(
                filter: {
                    relativePath: {
                        glob: "features/rich-original-content/*.png"
                    }
                }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                height: 295
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const illustrations = flattenEdgesNodes(data.allFile)
    const contentFeatures = flattenEdgesNodes(data.allContentFeaturesYaml).map(
        (node) => ({
            id: node.id,
            icon: node.icon,
            title: node.title[locale.locale],
            content: node.content[locale.locale],
            illustration: getMatchingImageSrc(
                illustrations,
                node.illustration
            )!,
        })
    )

    const [activeTab, setActiveTab] = useState<TabType>(contentFeatures[0].id)
    const handleTabChange = useCallback(
        (id: TabType) => setActiveTab(id),
        [setActiveTab]
    )

    return (
        <StyledContentFeatures>
            <Tabs current={activeTab} onChange={handleTabChange}>
                {contentFeatures.map(({ id, icon, title }) => (
                    <Tab key={id} id={id}>
                        <TabIcon
                            icon={icon}
                            data-testid="features-contentfeatures-tabicon"
                        />
                        <TabTitle>{title}</TabTitle>
                    </Tab>
                ))}
            </Tabs>
            {contentFeatures.map(
                ({ id, title, content, illustration }) =>
                    activeTab === id && (
                        <ContentFeature key={id}>
                            <ContentMobileTitle>{title}</ContentMobileTitle>
                            <ContentFeatureContent>
                                {content}
                            </ContentFeatureContent>
                            <ContentFeatureIllustration
                                image={illustration}
                                data-testid="features-contentfeatures-illustration"
                            />
                        </ContentFeature>
                    )
            )}
        </StyledContentFeatures>
    )
}

const StyledContentFeatures = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightBackground};
`

const Tab = styled.div<{ isCurrent?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100px;
    cursor: ${({ isCurrent }) => (isCurrent ? 'default' : 'pointer')};
    background-color: ${({ isCurrent, theme }) =>
        isCurrent ? theme.colors.accent : 'rgba(255, 255, 255, 0.5)'};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 66px;
        justify-content: center;
    }

    &:after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: ${({ isCurrent }) => (isCurrent ? 'block' : 'none')};
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${({ theme }) => theme.colors.accent};
    }

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: bottom center;
        transform: scale3d(0.8, 0, 1);
        content: '';
        width: 100%;
        height: 7px;
        background-color: ${({ theme }) => theme.colors.accent};
        transition: all 300ms ease-out;
    }

    @media (hover: hover) {
        &:hover {
            &:before {
                transform: scale3d(1, 1, 1);
            }
        }
    }
`

const TabIcon = styled.div<{ icon: string }>`
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background-image: url('${({ icon }) =>
        withPrefix(`/images/icons/${icon}`)}');
    background-repeat: no-repeat;
    background-position: center bottom;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        background-position: center;
    }
`

const TabTitle = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 21px;
    font-weight: ${({ theme }) => theme.typography.weights.bold};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        display: none;
    }
`

const ContentFeature = styled.div`
    display: flex;
    padding: 32px;
    font-size: 14px;
    line-height: 24px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column;
        padding: 24px 12px 12px 12px;
    }
`

const ContentMobileTitle = styled.div`
    display: none;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    ${({ theme }) => theme.typography_presets.bold};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        display: inline-block;
    }
`

const ContentFeatureContent = styled.div`
    width: 50%;
    margin-right: 40px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
    }
`

const ContentFeatureIllustration = styled.div<{ image: string }>`
    width: 50%;
    height: 295px;
    background-image: url('${({ image }) => image}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        height: 200px;
    }
`
