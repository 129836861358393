import { IGatsbyImageData } from 'gatsby-plugin-image'

export const flattenEdgesNodes = <N>(root: {
    edges: {
        node: N
    }[]
}): N[] => root.edges.map((edge) => edge.node)

export interface ImageFileData {
    name: string
    extension: string
    childImageSharp: {
        gatsbyImageData: IGatsbyImageData
    }
}

export interface ImageFilesData {
    edges: {
        node: ImageFileData
    }[]
}

export const getImageFallbackSrc = (imageData: ImageFileData) =>
    imageData.childImageSharp.gatsbyImageData.images.fallback?.src

export const getMatchingImageSrc = (
    images: ImageFileData[],
    imageName: string
) =>
    getImageFallbackSrc(
        images.find(
            ({ name, extension }) => `${name}.${extension}` === imageName
        )!
    )
